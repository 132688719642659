import { combineReducers } from "redux";
import { SET_LANGUAGE, SET_LOGIN, SET_LOGOUT } from "../actions";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../const";

import StringKo from "../language/StringKo";

function getLanguage() {
  return navigator.language || navigator.userLanguage;
}
// let lang = getLanguage();
// if (lang) lang = lang.split("-")[0];
// if (lang != 'ko' && lang != 'en' && lang != 'ja') lang = 'en';

// const initState = {
//   language: lang,
//   string: lang === "ja"
//     ? StringJa
//     : lang === "en"
//     ? StringEn
//     : StringKo
// };
let lang = 'ko';
const initState = {
  language: lang,
  string: StringKo
};

const initLoginState = {
  isLogin: false,
  userinfo: {}
};

const stringReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return Object.assign(
        {},
        {
          language: action.language,
          string: StringKo
          // string:
          //   action.language === "ja"
          //     ? StringJa
          //     : action.language === "en"
          //     ? StringEn
          //     : StringKo
        }
      );
    default:
      return state;
  }
};
const loginReducer = (state = initLoginState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      reactLocalStorage.setObject(Const.appCode + "#userinfo", action.userinfo);
      return Object.assign(
        {},
        {
          isLogin: true,
          userinfo: action.userinfo
        }
      );
      case SET_LOGOUT:
        reactLocalStorage.remove(Const.appCode + "#userinfo");
        return Object.assign(
          {},
          {
            isLogin: false,
            userinfo: {}
          }
        );
    default:
      return state;
  }
};


const reducer = combineReducers({ stringReducer, loginReducer });

export default reducer;
