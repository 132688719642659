import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove(Const.appCode + "#userinfo");
          global.location.href = "/";
        }
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
};

const httpDownload = (url, params, data) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const imageUrl = (idx) => {
  return (
    Const.serverProtocol +
    "://" +
    Const.serverIp +
    ":" +
    Const.serverPort +
    Const.serverContext +
    "/file/" +
    idx
  );
};

const httpUrl = {
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",
  
  bonusList: '/bonus/list?pageNum=%s&pageSize=%s',
  bonusSwap: '/bonus/swap',

  paymentCreate: '/payment/create/%s',
  paymentList: '/payment/list',
  paymentProductList: '/payment/product/list',

  userSubList: '/user/tree/sublist/%s',
  userRecoSubList: '/user/tree/reco/sublist/%s',
  userTreeRegist: '/user/tree/regist',
  recommendRegist: '/user/recommeder/regist/%s',
  NotPlaced: '/user/notplaced',

  walletBalance: '/wallet/balance',
  walletBalanceExt: '/wallet/balance/ext',
  walletTransLogList: '/wallet/transLog/list?pageNum=%s&pageSize=%s&searchText=%s',
  walletTransLogWeekly: '/wallet/transLog/weekly',

  systemSetting: '/system/setting',
};

const imageType = ["image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType,
  imageUrl,
};
