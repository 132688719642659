
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl,} from "../api/httpClient";
import { useLocation, useNavigate } from "react-router-dom";
import con from '../const';
import { useSelector, useDispatch } from "react-redux";
import string from '../language/StringKo';
import { HomeOutlined, GiftOutlined, TeamOutlined, FileTextOutlined } from '@ant-design/icons';

const Bottom = (props) => {
    
    const string = useSelector(state => state.stringReducer.string); 
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(()=>{
    },[]);

    return (
        <div id="bottom">     
            <div className='bottom-container'>
                <div className='bottom-inner'>
                    <div onClick={()=>navigate('/home')} className='bottom-btn'>
                        <HomeOutlined style={{color: props.active == 1 ? '#FE3563' : '#ACC4E6', fontSize: 20}}/>
                    </div>
                    <div onClick={()=>navigate('/product')} className='bottom-btn'>
                        <GiftOutlined style={{color: props.active == 2 ? '#FE3563' : '#ACC4E6', fontSize: 20}}/>
                    </div>
                    <div onClick={()=>navigate('/organization')} className='bottom-btn'>
                        <TeamOutlined style={{color: props.active == 3 ? '#FE3563' : '#ACC4E6', fontSize: 20}}/>
                    </div>
                    <div onClick={()=>navigate('/history')} className='bottom-btn'>
                        <FileTextOutlined style={{color: props.active == 4 ? '#FE3563' : '#ACC4E6', fontSize: 20}}/>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default Bottom;