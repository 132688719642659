export default {
  appName: "bsmemberAdmin",

  /////////////////////////개발/////////////////////////////
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "18001",

  /////////////////////////운영/////////////////////////////
  serverProtocol: "https",
  serverIp: "back.worldmatrix.net",
  serverPort: "443",

  clientId: "",
  clientSecret: "",
  serverContext: "",
};
