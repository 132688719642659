import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  Table,
  Divider,
  Image,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  stringReducer,
  setLogin,
  setLogout,
  setCoinCurrency,
  setCoinList,
} from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import string from "../language/StringKo";
import moment from "moment";
import { comma } from "../lib/util/numberUtil";
import Header from "../components/Header";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import util from 'util';
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Tree from 'react-d3-tree';

const FormItem = Form.Item;
const Option = Select.Option;

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const userinfo = useSelector(state => state.loginReducer.userinfo);

  const [orgChart, setOrgChart] = useState({});
  const [notPlacedList, setNotPlacedList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedParent, setSelectedParent] = useState({});
  const [assignModalOpen, setAssignModalOpen] = useState(false);

  useEffect(() => {
    setOrgChart(
      [{
        name: userinfo.id,
        attributes: userinfo,
        children: []
      }]
    );
    getNotPlaced();
  }, []);
  const getNotPlaced = async () => {
    const result = await httpGet(httpUrl.NotPlaced, []);
    setNotPlacedList(result.data)
  }
  const getChildrenData = async (idx) => {

    const children = await httpGet(httpUrl.userSubList, [idx]);
    let response = [];
    for (let i=0;i<children.data.length;i++) {
      response.push({
        name: children.data[i].id,
        attributes: children.data[i],
        children: []
      })
    }
    return response;
  }
  const handleNodeClick = async (nodeDatum) => {
    const newOrgChart = [...orgChart];
    const children = await getChildrenData(nodeDatum.attributes.idx);

    console.log(newOrgChart)
    const addChildrenToNode = (nodes) => {
      for(let i=0;i<nodes.length;i++) {
        const node = nodes[i];
        if (node.name === nodeDatum.name) {
          node.children = children;
        } else if (node.children) {
          addChildrenToNode(node.children);
        }
      }
    };
    addChildrenToNode(newOrgChart);

    setOrgChart(newOrgChart)
    console.log(newOrgChart)
  };
  const renderHtmlNode = ({ nodeDatum, toggleNode }) => (
    <foreignObject width="200" height="100" x="-100" y="-50">
      <div className="tree-node" onClick={async ()=>{
          if (selectedUser.idx) {
            onPlaceUser(nodeDatum.attributes)
            return;
          }

          if (nodeDatum.children && nodeDatum.children.length > 0) toggleNode();
          else await handleNodeClick(nodeDatum);
        }}
      >
        {nodeDatum.attributes && (nodeDatum.attributes.pos == 1 ? (<div className="tree-node-pos-l">L</div>) : (<div className="tree-node-pos-r">R</div>))}
        <div className="tree-node-title">{nodeDatum.name}</div>
        <div>{string.code}: {nodeDatum.attributes ? nodeDatum.attributes.referralCode : ''}</div>
        <div>{string.sales}: {nodeDatum.attributes ? comma((nodeDatum.attributes.salesAmount/1000000).toFixed(0)) : ''}</div>
      </div>
    </foreignObject>
  );
  const handleCheckboxChange = (user) => {
    if (selectedUser.idx === user.idx) {
      setSelectedUser({});
    } else {
      setSelectedUser(user);
    }
  };

  const onPlaceUser = async (user) => {
    //매출있는 사용자인지 확인
    if (selectedUser.salesAmount <= 0) {
      Modal.info({
        title: string.placeUser,
        content: string.placeUserNoSales,
        okText: string.ok
      })
      return;
    }
    setSelectedParent(user);
    setAssignModalOpen(true);
  }

  const placeUser = async (pos) => {

    const result = await httpPost(httpUrl.userTreeRegist, [], {
      parentIdx: selectedParent.idx,
      userIdx: selectedUser.idx,
      pos
    });

    if (result.data == 'SUCCESS') {
      getNotPlaced();
      handleNodeClick({
        name: selectedParent.id,
        attributes: selectedParent
      })
      setAssignModalOpen(false);
      setSelectedParent({})
      setSelectedUser({})
    }
    else {
      if (result.data == 'ALREADY_ASSIGNED') alert(string.placeUserAlreadyAssigned);
      else if (result.data == 'CHILD_EXIST') alert(string.placeUserChildExist);
      else alert(string.errorDetail);
    }
  }

  return (
    <div id="organization">
      <Top title="ORGANIZATION" />

      <div className="content">
        <div className="referral">
          {string.myRecommendCode}: <span className="referral2">{userinfo.referralCode}</span>
        </div>
        <div className="select-type">
          <Select 
            defaultValue={1} 
            style={{width: '150px', position: 'absolute', marginTop: '10px'}}
            onChange={value => {
              navigate('/recommender')
            }} 
          >
            <Option value={1}>후원계보도</Option>
            <Option value={2}>직대계보도</Option>
          </Select>
        </div>
        <Tree
          data={orgChart}
          renderCustomNodeElement={renderHtmlNode}
          orientation="vertical"
          pathFunc="step" 
          translate={{
            x: 150,
            y: 100,
          }}
          zoom={1}
          separation={{ siblings: 2, nonSiblings: 2 }}
          />
          <div>
            <div>{string.notPlacedUser}</div>
            {notPlacedList.map(row=>{
              return (
                <div className="notplaced-user" onClick={() => handleCheckboxChange(row)}>
                  <div>
                    <Checkbox 
                      style={{marginRight: '10px'}}
                      checked={selectedUser.idx === row.idx}
                      onChange={() => handleCheckboxChange(row)}
                    />
                    {row.id}
                  </div>
                  <div>
                    {comma((row.salesAmount/1000000).toFixed(0))} KRW
                  </div>
                  
                </div>
              );
            })}
          </div>
      </div>
      
      <Modal
          title={string.placeUser}
          visible={assignModalOpen}
          onCancel={() => setAssignModalOpen(false)}
          footer={[
            <Button onClick={() => placeUser(1)}>
              {string.placeUserLeft}
            </Button>,
            <Button onClick={() => placeUser(2)}>
              {string.placeUserRight}
            </Button>,
            <Button onClick={() => setAssignModalOpen(false)}>
              {string.cancel}
            </Button>,
          ]}
          
          bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}  // 모달 본문의 스타일을 설정
        >
              <div className="place-modal">
                {util.format(string.placeUserSelectPosition, selectedParent.id, selectedUser.id)}
              </div>
        </Modal>
      <Bottom active={3} />
    </div>
  );
};

export default Product;
