import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  Table,
  Divider,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  stringReducer,
  setLogin,
  setLogout,
  setCoinCurrency,
  setCoinList,
} from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import string from "../language/StringKo";
import moment from "moment";
import { comma } from "../lib/util/numberUtil";
import Header from "../components/Header";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Top from "../components/Top";
import Bottom from "../components/Bottom";
const FormItem = Form.Item;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);

  const [buyModalVisible, setBuyModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [walletTransLogWeekly, setWalletTransLogWeekly] = useState([]);
  const [fee, setFee] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState('');

  useEffect(() => {
    getWalletBalance();
    getPaymentList();
    getWalletTransLogWeekly();
    getFee();
  }, []);

  const getWalletBalance = async () => {
    const result = await httpGet(httpUrl.walletBalance, []);
    setWalletBalance(result.data)
  }
  const getPaymentList = async () => {
    const result = await httpGet(httpUrl.paymentList, []);
    setPaymentList(result.data)
  }
  const getWalletTransLogWeekly = async () => {
    const result = await httpGet(httpUrl.walletTransLogWeekly, []);

    const today = moment();
    const oneWeekAgo = moment().subtract(6, 'days');
    let list = [];
    for (let date = moment(oneWeekAgo); date.isSameOrBefore(today); date.add(1, 'days')) {

      const createDate = date.format('MM/DD');
      const bonus = result.data.find(x => moment(x.createDate).format('MM/DD') == date.format('MM/DD') && x.type == 'BONUS')
      const withdraw = result.data.find(x => moment(x.createDate).format('MM/DD') == date.format('MM/DD') && x.type == 'SWAP')

      list.push({
        createDate,
        bonus: bonus ? bonus.skrAmount / 1000000000 : 0,
        withdraw: withdraw ? Math.abs(withdraw.skrAmount) / 1000000000 : 0
      })
    }

    setWalletTransLogWeekly(list)
  }
  const getFee = async () => {
    const result = await httpGet(httpUrl.systemSetting, []);
    const value = result.data.find(x => x.type == 'WITHDRAW_FEE').value;
    setFee(value)
  }

  const swap = async () => {
    if (!withdrawAmount || isNaN(withdrawAmount)) {
      alert('숫자를 입력해주세요')
      return;
    }
    
    if (walletBalance < (parseFloat(withdrawAmount) + parseFloat(fee)) * 1000000000) {
      alert('잔액이 부족합니다.')
      return;
    }

    const result = await httpPost(httpUrl.bonusSwap, [], {amount: withdrawAmount * 1000000000});
    if (result.data == 'SUCCESS') {
      getWalletBalance();
      getWalletTransLogWeekly();
      setWithdrawModalVisible(false)
    }
    else {
      if (result.data == 'NO_BALANCE') alert(string.withdrawNoBalance)
      else alert(string.errorDetail)
    }
  }

  return (
    <div id="home">
      <Top title="HOME" />

      <div className="content">
        <div className="sub-title">Total Balance</div>
        <div className="sub-btn-wrapper">
          <div className="sub-content">{comma((walletBalance / 1000000000).toFixed(4))} SKR</div>
          <div className="sub-btn" onClick={() => {
            setWithdrawAmount('')
            setWithdrawModalVisible(true)
            }}>{string.withdraw}</div>
        </div>
        <div className="sub-title">Total Buy</div>
        <div className="sub-btn-wrapper">
          <div className="sub-content">{comma((paymentList.reduce((p, c) => p + c.krwAmount, 0) / 1000000).toFixed(0))} KRW</div>
          <div className="sub-btn" onClick={() => setBuyModalVisible(true)}>{string.buyHistory}</div>
        </div>
        <div className="sub-title">Weekly Statistics</div>
        <div className="sub-graph">
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={walletTransLogWeekly}
              margin={{}}
            >
              <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4} />
                </linearGradient>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#494949" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#494949" stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" stroke="#eeeeee" />
              <XAxis dataKey="createDate" tick={{ fontSize: 14, fill: '#b1b1b1' }} />
              {/* <YAxis tick={{ fontSize: 14, fill: '#b1b1b1' }} /> */}
              <Tooltip
                contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '10px' }}
                cursor={{ fill: 'rgba(200, 200, 200, 0.3)' }}
              />
              <Legend wrapperStyle={{ fontSize: 14 }} />
              <Bar dataKey="bonus" fill="url(#colorPv)" barSize={10} radius={[10, 10, 0, 0]} />
              <Bar dataKey="withdraw" fill="url(#colorUv)" barSize={10} radius={[10, 10, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="box-wrapper">
          <div className="box01">
            <span className="box-title">Bonus</span><br />
            <span className="box-content">{comma(walletTransLogWeekly.reduce((p, c) => p + c.bonus, 0).toFixed(4))}</span> <span className="box-currency"> SKR</span>

          </div>
          <div className="box02">
            <span className="box-title">Withdraw</span><br />
            <span className="box-content">{comma(walletTransLogWeekly.reduce((p, c) => p + c.withdraw, 0).toFixed(4))}</span> <span className="box-currency"> SKR</span>
          </div>
        </div>
        <Modal
          title={string.buyHistory}
          visible={buyModalVisible}
          onCancel={() => setBuyModalVisible(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setBuyModalVisible(false)}>
              {string.ok}
            </Button>,
          ]}
          bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}  // 모달 본문의 스타일을 설정
        >
          {paymentList.map(row => {
            return (
              <div className="home-list-item">
                <div className="home-list-row">
                  <div className="home-list-name">
                    {row.productName}
                  </div>
                  <div className="home-list-amount">
                    {comma(row.krwAmount / 1000000)} <span className="home-list-amount-unit">KRW</span>
                  </div>
                </div>
                <div className="home-list-row">
                  <div className="home-list-date">
                    {moment(row.createDate).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                  <div className="home-list-amount2">
                    {comma((row.skrAmount / 1000000000).toFixed(4))} <span className="home-list-amount-unit">SKR</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Modal>
        <Modal
          title={string.withdraw}
          visible={withdrawModalVisible}
          onOk={swap}
          okText={string.ok}
          onCancel={() => setWithdrawModalVisible(false)}
          cancelText={string.cancel}
          bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}  // 모달 본문의 스타일을 설정
        >
          <div className="">
            <div className="">
              출금할 수량을 입력해주세요. <br />
              노드마스크 지갑으로 SKR이 전송됩니다. <br/>
              수수료: {fee} SKR
            </div>
            <input 
              className="home-form-input"
              autoComplete='false'
              type='number'
              onChange={(e)=>setWithdrawAmount(e.target.value)}
              value={withdrawAmount}
            />
          </div>
        </Modal>
      </div>
      <Bottom active={1} />
    </div>
  );
};

export default Home;
