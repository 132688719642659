import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  Table,
  Divider,
  Image,
} from "antd";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  stringReducer,
  setLogin,
  setLogout,
  setCoinCurrency,
  setCoinList,
} from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import string from "../language/StringKo";
import moment from "moment";
import { comma } from "../lib/util/numberUtil";
import Header from "../components/Header";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Top from "../components/Top";
import Bottom from "../components/Bottom";
const FormItem = Form.Item;

const History = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);

  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(false); // 데이터 가져오기 상태
  const loader = useRef(null); // Intersection Observer 대상 Ref
  const [page, setPage] = useState(1); // 현재 페이지 상태
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      setPage((prev) => prev + 1);
    }
  }, []);
  
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => observer.disconnect();
  }, [handleObserver]);

  useEffect(() => {
    getList(page);
  }, [page]);

  const getList = async () => {
    setIsFetching(true);
    
    const newList = await httpGet(httpUrl.walletTransLogList, [page, 20]);
    if (newList.data.list.length === 0) setHasMore(false);
    else setList([...list, ...newList.data.list]);
    
    setIsFetching(false);
  }

  return (
    <div id="history">
      <Top title="HISTORY" />

      <div className="content">
        <div className="list-wrapper">
          {list.length == 0 && (
            <div style={{textAlign: 'center', paddingTop: '100px'}}>{string.nodata}</div>
          )}
          {list.map(row=>{

            let typeClass = '';
            if (row.type == 'BONUS') typeClass='list-bonus';
            else typeClass='list-swap';

            return (
              <div className="list-item">
                <div className="list-row">
                  <div className={typeClass}>
                    {row.type == 'BONUS' ? string.bonus : string.swap}
                  </div>
                  <div className="list-amount">
                    {comma((row.skrAmount/1000000000).toFixed(4))} <span className="list-amount-unit">SKR</span>
                  </div>
                </div>
                <div className="list-row">
                  <div className="list-date">
                    {moment(row.createDate).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                  <div className="list-amount2">
                  <span className="list-amount-balance">{string.myValue}:</span> {comma((row.toSkrBalance/1000000000).toFixed(4))} <span className="list-amount-unit">SKR</span>
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={loader} className="loading">
            {isFetching && 'Loading more items...'}
          </div>
        </div>
      </div>
      <Bottom active={4} />
    </div>
  );
};

export default History;
