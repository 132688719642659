import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  Table,
  Divider,
  Image,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  stringReducer,
  setLogin,
  setLogout,
  setCoinCurrency,
  setCoinList,
} from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import string from "../language/StringKo";
import moment from "moment";
import { comma } from "../lib/util/numberUtil";
import Header from "../components/Header";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import util from 'util';
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Tree from 'react-d3-tree';

const FormItem = Form.Item;
const Option = Select.Option;

const Recommender = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const userinfo = useSelector(state => state.loginReducer.userinfo);

  const [orgChart, setOrgChart] = useState({});

  useEffect(() => {
    setOrgChart(
      [{
        name: userinfo.id,
        attributes: userinfo,
        children: []
      }]
    );
  }, []);
  const getChildrenData = async (idx) => {

    const children = await httpGet(httpUrl.userRecoSubList, [idx]);
    let response = [];
    for (let i=0;i<children.data.length;i++) {
      response.push({
        name: children.data[i].id,
        attributes: children.data[i],
        children: []
      })
    }
    return response;
  }
  const handleNodeClick = async (nodeDatum) => {
    const newOrgChart = [...orgChart];
    const children = await getChildrenData(nodeDatum.attributes.idx);

    console.log(newOrgChart)
    const addChildrenToNode = (nodes) => {
      for(let i=0;i<nodes.length;i++) {
        const node = nodes[i];
        if (node.name === nodeDatum.name) {
          node.children = children;
        } else if (node.children) {
          addChildrenToNode(node.children);
        }
      }
    };
    addChildrenToNode(newOrgChart);

    setOrgChart(newOrgChart)
  };
  const renderHtmlNode = ({ nodeDatum, toggleNode }) => (
    <foreignObject width="200" height="100" x="-100" y="-50">
      <div className="tree-node" onClick={async ()=>{
          if (nodeDatum.children && nodeDatum.children.length > 0) toggleNode();
          else await handleNodeClick(nodeDatum);
        }}
      >
        <div className="tree-node-title">{nodeDatum.name}</div>
        <div>{string.code}: {nodeDatum.attributes ? nodeDatum.attributes.referralCode : ''}</div>
        <div>{string.sales}: {nodeDatum.attributes ? comma((nodeDatum.attributes.salesAmount/1000000).toFixed(0)) : ''}</div>
      </div>
    </foreignObject>
  );

  return (
    <div id="organization">
      <Top title="ORGANIZATION" />

      <div className="content">
        <div className="referral">
          {string.myRecommendCode}: <span className="referral2">{userinfo.referralCode}</span>
        </div>
        <div className="select-type">
          <Select 
            defaultValue={2} 
            style={{width: '150px', position: 'absolute', marginTop: '10px'}}
            onChange={value => {
              navigate('/organization')
            }} 
          >
            <Option value={1}>후원계보도</Option>
            <Option value={2}>직대계보도</Option>
          </Select>
        </div>
        <Tree
          data={orgChart}
          renderCustomNodeElement={renderHtmlNode}
          orientation="vertical"
          pathFunc="step" 
          translate={{
            x: 150,
            y: 100,
          }}
          zoom={1}
          separation={{ siblings: 2, nonSiblings: 2 }}
          />
      </div>
      
      <Bottom active={3} />
    </div>
  );
};

export default Recommender;
