import { Input, Modal, Popover, Button } from "antd";
import React, { useState, useEffect } from "react";
import { httpUrl, httpPost, httpGet } from "../api/httpClient";
import { stringReducer, setLogout } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faGlobe } from "@fortawesome/free-solid-svg-icons";
import Const from "../const";

const Referral = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const navigate = useNavigate();
  const [referralCode, setReferralCode] = useState('');
  const [globalOpen, setGlobalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  useEffect(() => {
  }, []);

  const changeLan = (lan) => {
    dispatch(stringReducer(lan));
  };

  const regist = async ()=> {
    setErrorMessage('')
    if (!referralCode || referralCode.length != 6) {
      setErrorMessage(string.referralInput)
      return;
    }
    const result = await httpPost(httpUrl.recommendRegist, [referralCode]);
    if (result.data) navigate('/home');
    else {
      setErrorMessage(string.referralNotFound)
    }
  }

  return (
    <div id="referral">
      {/* 헤더 */}
      <div className="header">
        <Popover
          content={
            <div className="pophover">
              <div
                onClick={() => changeLan("ko")}
                className={language == "ko" ? "lan active" : "lan"}
              >
                한국어
              </div>
              <div
                onClick={() => changeLan("en")}
                className={language == "en" ? "lan active" : "lan"}
              >
                English
              </div>
              <div
                onClick={() => changeLan("ja")}
                className={language == "ja" ? "lan active" : "lan"}
              >
                日本語
              </div>
            </div>
          }
          trigger={"click"}
          open={globalOpen}
          onOpenChange={() => setGlobalOpen(!globalOpen)}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="hoverEffect"
          >
            <FontAwesomeIcon icon={faGlobe} className="globalImg" />
          </div>
        </Popover>
      </div>
      <div className="container">
        <div className="title">
          {string.referralSetting}
        </div>
        <div className="desc">
          {string.referralSettingDesc}
        </div>
        <div className="formBox">
          <FontAwesomeIcon
            icon={faUser}
            style={{ width: 20, marginRight: 16, marginLeft: 16 }}
            className="icon"
          />
          <input
            autoComplete="false"
            placeholder={string.code}
            style={{ margin: 0 }}
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value.trim())}
          />
        </div>
        <div className="error-message">{errorMessage}</div>
        <div className="btn-container">
        <button
          className="prev-btn"
          onClick={()=>{
            dispatch(setLogout());
            navigate("/", { state: { logout: true } });
          }}
        >
          {string.prev}
        </button>
        <div style={{width: '30px'}}></div>
        <button
          className="loginBtn"
          type="primary"
          onClick={regist}
        >
          {string.next}
        </button>
        </div>
        {/* <button
          className="loginBtn"
          type="primary"
          onClick={handleSubmit}
        >
          {string.login}
        </button> */}
      </div>

    </div>
  );
};

export default Referral;
