const string = {
  //메뉴
  home: '홈',
  product: '상품',
  organization: '조직',
  history: '내역',
  
  //공통
  completed: "완료",
  ok: "확인",
  cancel: "취소",
  fail: "실패",
  sent: "출금",
  sending: "출금중",
  received: "입금",
  receiving: "입금중",
  value: "금액",
  myValue: "잔액",
  fee: "수수료",
  more: "More",
  localCoinUnit: ["원", "USD", "CNY", "JPY"],
  error: "오류",
  errorDetail: "오류가 발생했습니다.",
  bonus: '보너스',
  swap: '출금',
  nodata: '데이터가 없습니다.',
  code: '코드',
  sales: '매출',
  next: '다음',
  prev: '이전',

  //로그인
  login: "로그인",
  loginFailedMessage: "로그인에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.",
  loginFailedTitle: "로그인 실패",
  loginFailedWrongId: "잘못된 아이디입니다.",
  loginFailedWrongPassword: "잘못된 비밀번호 입니다.",
  loginFailedInvalidLoginInfo: "로그인 정보가 잘못되었습니다.",
  loginFailedExpiredId: "사용이 중지된 아이디입니다.",
  savedId: "아이디 저장",
  autoLogin: "자동 로그인",
  findPw: "비밀번호 찾기",
  signUp: "회원가입",
  ok: "확인",
  signUpMsg03: "메일인증을 완료 후 로그인 하실 수 있습니다.",
  idEmail: "아이디(이메일)",
  password: "비밀번호",
  signUpRuleMsg09: "이메일을 입력해주세요",
  signUpRuleMsg10: "비밀번호를 입력해주세요",
  myinfoLogout: "로그아웃",
  myinfoLogoutConfirm: "로그아웃 하시겠습니까?",
  cancel: "취소",
  referralSetting: '추천인 설정',
  referralSettingDesc: '추천한 사람의 코드를 정확하게 입력해주세요. 추천 코드는 한번 설정하면 변경할 수 없습니다.',
  referralInput: '추천인 코드 6자리를 입력해주세요.',
  referralNotFound: '추천인을 찾을 수 없습니다.',
  //home
  withdraw: '출금하기',
  buyHistory: '구매내역',
  withdrawNoBalance: '잔액이 부족합니다.',

  //product
  productList: '상품목록',
  productBuy: '구매하기',
  productBuyConfirm: '노드마스크 지갑의 SKR을 사용하여 상품을 구매합니다. %s 상품을 구매하시겠습니까?',
  productBuySuccess: '상품 구매가 완료되었습니다.',
  productBuyInfoError: '상품정보 오류가 발생하였습니다.',
  productBuyWalletError: '지갑연동에 오류가 발생하였습니다.',
  productBuyNoBalance: '잔액이 부족합니다.',

  //organization
  myRecommendCode: '내 추천코드',
  notPlacedUser: '배치대기 추천인',
  placeUser: '배치',
  placeUserLeft: '좌측배치',
  placeUserRight: '우측배치',
  placeUserNoSales: '사용자 매출 발생 후 배치가 가능합니다.',
  placeUserSelectPosition: '%s의 하위로 %s를 배치합니다. 좌측과 우측 중 하나를 선택하여 배치할 수 있습니다. 배치할 위치를 선택해주세요',
  placeUserAlreadyAssigned: '이미 배치가 끝난 사용자입니다.',
  placeUserChildExist: '배치하는 위치에 이미 다른 사용자가 할당되어 있습니다.',
};

export default string;
