import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./components/Main";
import "./css/pages.scss";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Organization from "./pages/Organization";
import Product from "./pages/Product";
import History from "./pages/History";
import Referral from "./pages/Referral";
import Recommender from "./pages/Recommender";

function App() {
  useEffect(() => {
  }, []);

  return (
    <div id="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/referral" element={<Referral />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/product" element={<Product />} />
            <Route exact path="/organization" element={<Organization />} />
            <Route exact path="/recommender" element={<Recommender />} />
            <Route exact path="/history" element={<History />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
