import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  Table,
  Divider,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  stringReducer,
  setLogin,
  setLogout,
  setCoinCurrency,
  setCoinList,
} from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import string from "../language/StringKo";
import moment from "moment";
import { comma } from "../lib/util/numberUtil";
import Header from "../components/Header";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import util from 'util';
import Top from "../components/Top";
import Bottom from "../components/Bottom";
const FormItem = Form.Item;

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.stringReducer.language);
  const string = useSelector((state) => state.stringReducer.string);
  const [list, setList] = useState([]);
  const [balance, setBalance] = useState(0);
  const [skrPrice, setSkrPrice] = useState(1);

  useEffect(() => {
    getList();
    getBalance();
    getSkrPrice();
  }, []);
  const getList = async () => {
    const result = await httpGet(httpUrl.paymentProductList, []);
    setList(result.data)
  }
  const getBalance = async () => {
    const result = await httpGet(httpUrl.walletBalanceExt, []);
    setBalance(result.data)
  }
  const getSkrPrice = async () => {
    const result = await httpGet(httpUrl.systemSetting, []);
    const value = result.data.find(x => x.type == 'SKR_PRICE').value;
    setSkrPrice(value)
  }
  const buy = async (product) => {
    Modal.confirm({
      title: string.productBuy,
      content: util.format(string.productBuyConfirm, product.name),
      okText: string.ok,
      cancelText: string.cancel,
      onOk() {
        httpPost(httpUrl.paymentCreate, [product.idx]).then(result=>{
          if (result.data == 'SUCCESS') {
            alert(string.productBuySuccess)
            getBalance();
          }
          else {
            if (result.data == 'NO_PRODUCT') alert(string.productBuyInfoError)
            else if (result.data == 'WALLET_ERROR') alert(string.productBuyWalletError)
            else if (result.data == 'NO_BALANCE') alert(string.productBuyNoBalance)
            else alert(string.errorDetail)
          }
        }).catch(e=>{
          alert(string.errorDetail)
        })
      },
      onCancel() {
      },
    });
  }

  return (
    <div id="product">
      <Top title="PRODUCT" />

      <div className="content">
        <div className="balance-box">
          <div className="sub-title1">Wallet Balance</div>
          <div className="sub-content1">{comma((balance/1000000000).toFixed(4))} <span className="skr-unit">SKR</span></div>
        </div>
        <div className="sub-title2">{string.productList}</div>
        <div className="list-wrapper">
          {list.map(row => {
            return (
              <div className="list-item">
                <div className="list-product-name">
                  {row.name}
                </div>
                <div className="list-product-price">
                  {comma(row.krwPrice / 1000000)} KRW<br />
                  <span className="list-product-price2">≈ {comma((row.krwPrice / 1000000 / skrPrice).toFixed(4))} SKR</span>
                </div>
                <div className="list-product-btn" onClick={()=>buy(row)}>
                  {string.productBuy}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Bottom active={2} />
    </div>
  );
};

export default Product;
